<template>
    <main id="main" class="main">
        <!-- form -->
        <section class="form form-top">
            <div class="form__header">
                <h2 class="form__name">パスワードの変更</h2>
            </div>
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <li id="form__item" class="form__item">
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line input__line-pass">
                                        <div id="input__item-pass" class="input__item-pass-pack">
                                            <!-- pass -->
                                            <div id="input__item-pass" class="input__item input__item-pass">
                                                <dt id="input__header-pass" class="input__header input__header-pass">
                                                    <label id="input__name-pass" class="input__name input__name-pass" for="input__pass"
                                                        >現在のパスワード</label
                                                    >
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd id="input__container-pass" class="input__container input__container-pass password">
                                                    <input
                                                        id="input__pass"
                                                        class="input__form input__pass"
                                                        type="password"
                                                        name="input__pass"
                                                        v-model="password"
                                                    />
                                                    <button class="btn-disppass"></button>
                                                </dd>
                                                <p class="input__error-text">現在のパスワードを入力してください</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line input__line-pass">
                                        <div id="input__item-pass" class="input__item-pass-pack">
                                            <!-- pass -->
                                            <div id="input__item-pass-new" class="input__item input__item-pass">
                                                <dt id="input__header-pass" class="input__header input__header-pass">
                                                    <label id="input__name-pass" class="input__name input__name-pass" for="input__pass"
                                                        >新しいパスワード</label
                                                    >
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd id="input__container-pass" class="input__container input__container-pass password">
                                                    <input
                                                        id="input__pass"
                                                        class="input__form input__pass"
                                                        type="password"
                                                        name="input__pass"
                                                        v-model="password_new"
                                                    />
                                                    <button class="btn-disppass"></button>
                                                </dd>
                                                <p class="input__error-text">
                                                    {{ password_new_error_msg }}
                                                </p>
                                            </div>
                                            <!-- pass-confirm -->
                                            <div id="input__item-pass-confirm" class="input__item input__item-pass-confirm">
                                                <dt id="input__header-pass-confirm" class="input__header input__header-pass-confirm">
                                                    <label
                                                        id="input__name-pass-confirm"
                                                        class="input__name input__name-pass-confirm"
                                                        for="input__pass-confirm"
                                                        >新しいパスワード（確認用）</label
                                                    >
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd
                                                    id="input__container-pass-confirm"
                                                    class="input__container input__container-pass-confirm password"
                                                >
                                                    <input
                                                        id="input__pass-confirm"
                                                        class="input__form input__pass-confirm"
                                                        type="password"
                                                        name="input__pass-confirm"
                                                        v-model="password_confirm"
                                                    />
                                                    <button class="btn-disppass"></button>
                                                </dd>
                                                <p class="input__error-text">
                                                    {{ password_conf_error }}
                                                </p>
                                            </div>
                                        </div>
                                        <p class="input__text">
                                            パスワードは利用者IDを含まない8文字以上20文字以内の英大文字、英小文字、数字の組み合わせで入力してください。<br />
                                            例）Miyazaki1020<br />
                                            使用可能な記号&emsp;! @ # $ % ^ &amp; * ( ) _ + - = { } [ ] : &lt; &gt; , . ? / ; ' &quot; |
                                        </p>
                                    </div>
                                </dl>
                                <!-- <img src="@/images/items/recapchadummy.png" style="margin: 2rem auto 1rem;"> -->
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
                <button class="btn btn-next" type="button" v-on:click="changePass">パスワードを変更する</button>
            </div>
        </section>
        <!-- /form -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { API_USERS, API_ORDER, isNull } from '@/lib/util'
import { RECAPTCHA_AUTH_ERROR } from '@/lib/constants_error'
import { isPassword, isPasswordLonger, isPasswordShorter, isPasswordCombination } from '@/lib/validation_util'
import { useStore } from '@/store'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const recaptchaInstance = useReCaptcha()
        const password = ref('')
        const password_new = ref('')
        const password_confirm = ref('')
        const password_new_error_msg = ref('')
        const password_conf_error = ref('')

        // マイページへ
        const moveMyPage = () => {
            router.push('/member/my_page')
        }
        // パスワード変更
        const changePass = async () => {
            if (chkValidation()) {
                store.dispatch('setIsProcessing', true)
                await recaptchaInstance?.recaptchaLoaded()
                const token = await recaptchaInstance?.executeRecaptcha('login')

                const postData = {
                    password: password.value,
                    password_new: password_new.value,
                    token: token,
                }
                let data = await apiClient
                    .patch(`${API_USERS}/member/password`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        context.emit('displayMessage', {
                            msg_title: 'パスワード変更完了',
                            msg: ['パスワード変更が完了しました。'],
                            ok_btn: true,
                        })
                        return data
                    })
                    .catch((e: AxiosError<any>) => {
                        // エラー処理
                        console.log(e.message)
                        let message: string[] = ['パスワード変更に失敗しました。']
                        if (!isNull(e.response) && !isNull(e.response?.data)) {
                            if (!isNull(e.response?.data?.message) && e.response?.data.message == RECAPTCHA_AUTH_ERROR) {
                                message = [
                                    'セキュリティ対策により、認証が正常に行えませんでした（reCAPTCHAによるBOT判定）',
                                    '　',
                                    'お客様のアクセス環境により、セキュリティ対策が反応して認証が正常に行えないことがあります。',
                                    '以下の方法をお試しいただくことで、正常にご利用いただける場合があります。',
                                    '・入力をゆっくりおこない、予測変換や短縮入力を避ける',
                                    '・現在ご利用のブラウザを閉じ、別のブラウザで操作をおこなう',
                                    '・他の端末（PC⇔スマートフォンなど）で操作をおこなう',
                                    '・しばらく時間をおいてから、再度操作をおこなう',
                                ]
                            }
                        }
                        context.emit('displayMessage', {
                            msg_title: '変更に失敗しました',
                            msg: message,
                            close_btn: true,
                        })
                    })
                store.dispatch('setIsProcessing', false)
            }
        }
        // 入力チェック
        const chkValidation = (): boolean => {
            let err_count = 0
            // エラー表示初期化
            $('#input__item-pass').removeClass('input__error')
            $('#input__item-pass-new').removeClass('input__error')
            $('#input__item-pass-confirm').removeClass('input__error')
            password_new_error_msg.value = ''
            password_conf_error.value = ''

            // 現在のパスワード
            if (isNull(password.value)) {
                $('#input__item-pass').addClass('input__error')
                err_count++
            }
            // 新しいパスワード
            if (isNull(password_new.value) || !isPassword(password_new.value)) {
                $('#input__item-pass-new').addClass('input__error')
                err_count++
                if (isPasswordLonger(password_new.value)) {
                    password_new_error_msg.value = 'パスワードは20文字以内で入力してください。'
                } else if (isPasswordShorter(password_new.value)) {
                    password_new_error_msg.value = 'パスワードは8文字以上で入力してください。'
                } else if (isPasswordCombination(password_new.value)) {
                    password_new_error_msg.value = 'パスワードは英大文字、英小文字、数字の組み合わせで入力してください。'
                }
            } else if (password_new.value.toLowerCase().includes(store.getters.member_id)) {
                $('#input__item-pass-new').addClass('input__error')
                err_count++
                password_new_error_msg.value = '利用者IDを含むパスワードは使用できません。'
            } else if (password.value == password_new.value) {
                $('#input__item-pass-new').addClass('input__error')
                err_count++
                password_new_error_msg.value = '現在のパスワードと同じパスワードです。'
            }
            // 新しいパスワード（確認用）
            if (isNull(password_confirm.value)) {
                $('#input__item-pass-confirm').addClass('input__error')
                password_conf_error.value = '新しいパスワード（確認用）を入力してください'
                err_count++
            } else if (password_new.value != password_confirm.value) {
                $('#input__item-pass-confirm').addClass('input__error')
                password_conf_error.value = '新しいパスワードと確認用のパスワードは同じものを入力してください'
                err_count++
            }

            return err_count == 0
        }

        return {
            password,
            password_new,
            password_confirm,
            password_new_error_msg,
            password_conf_error,

            moveMyPage,
            changePass,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'ChangePassword',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
    },
    methods: {},
    mounted: function () {
        //password disp
        $(function () {
            $('.password button').click(function () {
                if ($(this).prev('input').is('[type=text]')) {
                    $(this).prev('input').attr('type', 'password')
                    $(this).removeClass('disppass')
                } else if ($(this).prev('input').is('[type=password]')) {
                    $(this).prev('input').attr('type', 'text')
                    $(this).addClass('disppass')
                }
            })
        })
    },
})
</script>

<style scoped></style>
