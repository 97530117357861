<template>
    <main id="main" class="main">
        <!-- form -->
        <section class="form form-top">
            <div class="form__header">
                <h2 class="form__name">パスワードの再設定</h2>
            </div>
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <li id="form__item" class="form__item">
                        <div class="form__item-container">
                            <div class="input">
                                <p class="input__text">会員登録時に入力した、利用者ID・メールアドレス・お名前を入力してください。</p>
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line input__line-pass">
                                        <div id="input__item-pass" class="input__item-pass-pack">
                                            <div id="error-text-send-pass-id" class="input__item input__item-pass">
                                                <dt id="input__header-pass" class="input__header input__header-pass">
                                                    <label id="input__name-pass" class="input__name input__name-pass" for="">利用者ID</label>
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd id="input__container-pass" class="input__container input__container-pass password">
                                                    <input class="input__form input__mail" v-model="id" />
                                                </dd>
                                                <p class="input__error-text">{{ id_error }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line input__line-pass">
                                        <div id="input__item-pass" class="input__item-pass-pack">
                                            <div id="error-text-send-id-mail" class="input__item input__item-pass">
                                                <dt id="input__header-pass" class="input__header input__header-pass">
                                                    <label id="input__name-pass" class="input__name input__name-pass" for="">メールアドレス</label>
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd id="input__container-pass" class="input__container input__container-pass password">
                                                    <input class="input__form input__mail" v-model="email" />
                                                </dd>
                                                <p class="input__error-text">{{ email_error }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <div id="input__item-fullname-katakana" class="input__item input__item-fullname">
                                            <dt id="input__header-pass" class="input__header input__header-pass">
                                                <label id="input__name-pass" class="input__name input__name-pass" for=""
                                                    >お名前（全角カタカナ）</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-fullname" class="input__container input__container-fullname">
                                                <div id="input__firstname-pack" class="input__firstname-pack">
                                                    <span>セイ</span>
                                                    <input class="input__form input__firstname" v-model="family_name_katakana" />
                                                </div>
                                                <div id="input__lastname-pack" class="input__lastname-pack">
                                                    <span>メイ</span>
                                                    <input class="input__form input__lastname" v-model="given_name_katakana" />
                                                </div>
                                            </dd>
                                            <p class="input__error-text">{{ name_error }}</p>
                                        </div>
                                    </div>
                                </dl>
                                <!-- <img src="@/images/items/recapchadummy.png" style="margin: 2rem auto 1rem;"> -->
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" v-on:click="returnLogin">ログインに戻る</button>
                <button class="btn btn-next" type="button" v-on:click="sendPass">再設定メールを送信</button>
            </div>
        </section>
        <!-- /form -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import { AxiosResponse, AxiosError } from 'axios'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { API_USERS, isNull } from '@/lib/util'
import { RECAPTCHA_AUTH_ERROR } from '@/lib/constants_error'
import { isZenKatakana, isMail, isMemberId } from '@/lib/validation_util'
import $ from 'jquery'
import apiClient from '@/plugins/axioswrap'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const recaptchaInstance = useReCaptcha()

        const id = ref('')
        const email = ref('')
        const family_name_katakana = ref('')
        const given_name_katakana = ref('')
        const id_error = ref('')
        const email_error = ref('')
        const name_error = ref('')

        const initialize = () => {
            id.value = ''
            email.value = ''
            family_name_katakana.value = ''
            given_name_katakana.value = ''
            id_error.value = ''
            email_error.value = ''
            name_error.value = ''
            // エラー表示初期化
            $('#error-text-send-pass-id').removeClass('input__error')
            $('#error-text-send-pass-mail').removeClass('input__error')
            $('#input__item-fullname-katakana').removeClass('input__error')
        }

        // 入力チェック
        const chkValidate = (): boolean => {
            console.log('↓↓↓ notification.chkValidate')
            let err_count = 0
            id_error.value = ''
            email_error.value = ''
            name_error.value = ''
            // エラー表示初期化
            $('#error-text-send-pass-id').removeClass('input__error')
            $('#error-text-send-id-mail').removeClass('input__error')
            $('#input__item-fullname-katakana').removeClass('input__error')
            // 会員ID
            if (isNull(id.value)) {
                $('#error-text-send-pass-id').addClass('input__error')
                id_error.value = '利用者IDを入力してください'
                err_count++
            } else if (!isMemberId(id.value)) {
                $('#error-text-send-pass-id').addClass('input__error')
                id_error.value = '利用者IDは半角英数字（小文字）で入力してください'
                err_count++
            }
            // メールアドレス
            if (isNull(email.value)) {
                $('#error-text-send-id-mail').addClass('input__error')
                email_error.value = 'メールアドレスを入力してください'
                err_count++
            } else if (!isMail(email.value)) {
                $('#error-text-send-id-mail').addClass('input__error')
                email_error.value = 'メールアドレスが正しくありません'
                err_count++
            }
            // お名前
            if (isNull(family_name_katakana.value) || isNull(given_name_katakana.value)) {
                $('#input__item-fullname-katakana').addClass('input__error')
                name_error.value = 'お名前を入力してください'
                err_count++
            } else if (!isZenKatakana(family_name_katakana.value) || !isZenKatakana(given_name_katakana.value)) {
                $('#input__item-fullname-katakana').addClass('input__error')
                name_error.value = 'お名前は全角カタカナで入力してください'
                err_count++
            }

            console.log('↑↑↑ notification.chkValidate')
            return err_count == 0
        }

        // 確認メールを送信
        const sendPass = async () => {
            if (!chkValidate()) {
                return
            }
            store.dispatch('setIsProcessing', true)
            await recaptchaInstance?.recaptchaLoaded()
            const token = await recaptchaInstance?.executeRecaptcha('login')

            const postData = {
                member_id: id.value,
                email: email.value,
                family_name_katakana: family_name_katakana.value,
                given_name_katakana: given_name_katakana.value,
                token: token,
            }

            await apiClient
                .post(`${API_USERS}/login/password`, postData)
                .then(() => {
                    store.dispatch('setIsProcessing', false)
                    context.emit('displayMessage', {
                        msg_title: 'パスワードの再設定メール完了',
                        msg: [
                            'パスワードの再設定用メールを送信しました。',
                            'メールが届かない場合は、情報に不備がございますので、再度やり直してください。',
                        ],
                        close_btn: true,
                        close_event: returnLogin,
                    })
                })
                .catch((e: AxiosError<any>) => {
                    // エラー処理
                    store.dispatch('setIsProcessing', false)
                    console.log(e.message)
                    if (!isNull(e.response) && !isNull(e.response?.data)) {
                        if (!isNull(e.response?.data?.message) && e.response?.data.message == RECAPTCHA_AUTH_ERROR) {
                            context.emit('displayMessage', {
                                msg_title: 'メールの送信に失敗しました',
                                msg: [
                                    'セキュリティ対策により、認証が正常に行えませんでした（reCAPTCHAによるBOT判定）',
                                    '　',
                                    'お客様のアクセス環境により、セキュリティ対策が反応して認証が正常に行えないことがあります。',
                                    '以下の方法をお試しいただくことで、正常にご利用いただける場合があります。',
                                    '・入力をゆっくりおこない、予測変換や短縮入力を避ける',
                                    '・現在ご利用のブラウザを閉じ、別のブラウザで操作をおこなう',
                                    '・他の端末（PC⇔スマートフォンなど）で操作をおこなう',
                                    '・しばらく時間をおいてから、再度操作をおこなう',
                                ],
                                close_btn: true,
                            })
                            return
                        }
                    }
                    context.emit('displayMessage', {
                        msg_title: 'パスワードの再設定メール完了',
                        msg: [
                            'パスワードの再設定用メールを送信しました。',
                            'メールが届かない場合は、情報に不備がございますので、再度やり直してください。',
                        ],
                        close_btn: true,
                        close_event: returnLogin,
                    })
                })
        }

        const returnLogin = () => {
            // ログイン画面へ遷移
            router.push('/member/login')
        }

        let close = () => {
            context.emit('close')
        }
        return {
            id,
            email,
            family_name_katakana,
            given_name_katakana,
            id_error,
            email_error,
            name_error,

            sendPass,
            close,
            initialize,
            returnLogin,
        }
    },
    emits: ['close', 'displayMessage'],
    name: 'MemberIdNotification',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
    },
    methods: {},
    mounted: function () {
        console.log('↓↓↓MemberIdNotification.mounted↓↓↓')
        scrollTo(0, 0)
        this.initialize()
        console.log('↑↑↑MemberIdNotification.mounted↑↑↑')
    },
})
</script>

<style scoped></style>
