<!-- eslint-disable no-irregular-whitespace -->
<template>
    <!-- title -->
    <section class="title" v-show="isEditInput">
        <div class="title__container">
            <h1 class="title__name">会員情報の入力</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- form -->
        <section class="form">
            <div class="form__container">
                <div class="btn-container btn-container-right" v-if="isEditUpdate">
                    <button class="btn btn-remove" type="button" v-on:click="moveWithdrawal">退会する</button>
                </div>
            </div>
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <li id="form__item" class="form__item">
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line" v-if="isEditUpdate">
                                        <!-- dispmail -->
                                        <div id="input__item-dispmail" class="input__item input__item-dispmail">
                                            <dt id="input__header-dispmail" class="input__header input__header-dispmail">
                                                <label id="input__name-dispmail" class="input__name input__name-dispmail" for=""
                                                    >メールアドレス<br /><small
                                                        >（マイページのメールアドレス変更画面から変更してください）</small
                                                    ></label
                                                >
                                            </dt>
                                            <dd id="input__container-dispmail" class="input__container input__container-dispmail">
                                                <div class="output__form output__dispmail">
                                                    {{ email }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- dispid -->
                                        <div id="input__item-dispid" class="input__item input__item-dispid">
                                            <dt id="input__header-dispid" class="input__header input__header-dispid">
                                                <label id="input__name-dispid" class="input__name input__name-dispid" for=""
                                                    >利用者ID<br /><small>（変更不可）</small></label
                                                >
                                            </dt>
                                            <dd id="input__container-dispid" class="input__container input__container-dispid">
                                                <div class="output__form output__dispid">
                                                    {{ member_id }}
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line input__line-user-id" v-if="isEditInput">
                                        <div class="input__item-user-id-pack">
                                            <!-- user-id -->
                                            <div id="input__item-user-id" class="input__item input__item-user-id">
                                                <dt id="input__header-user-id" class="input__header input__header-user-id">
                                                    <label id="input__name-user-id" class="input__name input__name-user-id" for="input__user-id"
                                                        >利用者ID</label
                                                    >
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd id="input__container-user-id" class="input__container input__container-user-id">
                                                    <input
                                                        id="input__user-id"
                                                        class="input__form input__user-id"
                                                        type="text"
                                                        name="input__user-id"
                                                        v-model="member_id"
                                                    />
                                                    <button id="btn-address" class="btn btn-address" type="button" v-on:click="chkMemberId(false)">
                                                        利用者ID使用チェック
                                                    </button>
                                                </dd>
                                                <p class="input__member_success" v-if="!isNull(member_id_status) && member_id_status">
                                                    ○ このIDはご利用いただけます
                                                </p>
                                                <p class="input__member_error" v-if="!isNull(member_id_status) && !member_id_status">
                                                    × このIDはご利用いただけません
                                                </p>
                                                <p class="input__error-text">利用者IDは半角英数字（小文字）で入力してください</p>
                                            </div>
                                        </div>
                                        <p class="input__text">
                                            利用者IDは6文字以上20文字以内の英数字（小文字）の組み合わせで入力してください。<br />
                                            例）ferry2023<br />
                                            ※利用者IDは会員登録完了後変更できません。
                                        </p>
                                    </div>
                                    <!-- line -->
                                    <div id="input__item-pass" class="input__line input__line-pass" v-if="isEditInput">
                                        <div class="input__item-pass-pack">
                                            <!-- pass -->
                                            <div class="input__item input__item-pass">
                                                <dt id="input__header-pass" class="input__header input__header-pass">
                                                    <label id="input__name-pass" class="input__name input__name-pass" for="input__pass"
                                                        >パスワード</label
                                                    >
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd id="input__container-pass" class="input__container input__container-pass password">
                                                    <input
                                                        id="input__pass"
                                                        class="input__form input__pass"
                                                        type="password"
                                                        name="input__pass"
                                                        v-model="password"
                                                    />
                                                    <button class="btn-disppass"></button>
                                                </dd>
                                                <p id="password-error" class="input__error-text"></p>
                                            </div>
                                            <!-- pass-confirm -->
                                            <div id="input__item-pass-confirm" class="input__item input__item-pass-confirm">
                                                <dt id="input__header-pass-confirm" class="input__header input__header-pass-confirm">
                                                    <label
                                                        id="input__name-pass-confirm"
                                                        class="input__name input__name-pass-confirm"
                                                        for="input__pass-confirm"
                                                        >パスワード（確認用）</label
                                                    >
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd
                                                    id="input__container-pass-confirm"
                                                    class="input__container input__container-pass-confirm password"
                                                >
                                                    <input
                                                        id="input__pass-confirm"
                                                        class="input__form input__pass-confirm"
                                                        type="password"
                                                        name="input__pass-confirm"
                                                        v-model="password_confirm"
                                                    />
                                                    <button class="btn-disppass"></button>
                                                </dd>
                                                <p id="password-confirm-error" class="input__error-text"></p>
                                            </div>
                                        </div>
                                        <p class="input__text">
                                            パスワードは利用者IDを含まない8文字以上20文字以内の英大文字、小文字、半角数字の組み合わせで入力してください。<br />
                                            例）Miyazaki1020<br />
                                            使用可能な記号&emsp;! @ # $ % ^ &amp; * ( ) _ + - = { } [ ] : &lt; &gt; , . ? / ; ' &quot; |
                                        </p>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- name-kanji -->
                                        <div id="input__item-fullname" class="input__item input__item-fullname">
                                            <dt id="input__header-fullname" class="input__header input__header-fullname">
                                                <label id="input__name-fullname" class="input__name input__name-fullname" for="input__firstname-kanji"
                                                    >お名前</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-fullname" class="input__container input__container-fullname">
                                                <div id="input__firstname-pack" class="input__firstname-pack">
                                                    <span>姓</span>
                                                    <input
                                                        id="input__firstname-kanji"
                                                        class="input__form input__firstname"
                                                        name="input__firstname-kanji"
                                                        type="text"
                                                        v-model="family_name"
                                                    />
                                                </div>
                                                <div id="input__lastname-pack" class="input__lastname-pack">
                                                    <span>名</span>
                                                    <input
                                                        id="input__lastname-kanji"
                                                        class="input__form input__lastname"
                                                        name="input__lastname-kanji"
                                                        type="text"
                                                        v-model="given_name"
                                                    />
                                                </div>
                                            </dd>
                                            <p class="input__error-text">{{ name_error }}</p>
                                            <p id="input__text-fullname" class="input__text input__text-fullname">全角で入力してください</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- name-kana -->
                                        <div id="input__item-fullname-katakana" class="input__item input__item-fullname">
                                            <dt id="input__header-fullname" class="input__header input__header-fullname">
                                                <label id="input__name-fullname" class="input__name input__name-fullname" for="input__firstname-kana"
                                                    >お名前（全角カタカナ）</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-fullname" class="input__container input__container-fullname">
                                                <div id="input__firstname-pack" class="input__firstname-pack">
                                                    <span>セイ</span>
                                                    <input
                                                        id="input__firstname-kana"
                                                        class="input__form input__firstname"
                                                        name="input__firstname-kana"
                                                        type="text"
                                                        v-model="family_name_katakana"
                                                    />
                                                </div>
                                                <div id="input__lastname-pack" class="input__lastname-pack">
                                                    <span>メイ</span>
                                                    <input
                                                        id="input__lastname-kana"
                                                        class="input__form input__lastname"
                                                        name="input__lastname-kana"
                                                        type="text"
                                                        v-model="given_name_katakana"
                                                    />
                                                </div>
                                            </dd>
                                            <p class="input__error-text">{{ kana_error }}</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- tel -->
                                        <div id="input__item-tel" class="input__item input__item-tel">
                                            <dt id="input__header-tel" class="input__header input__header-tel">
                                                <label id="input__name-tel" class="input__name input__name-tel" for="input__tel01"
                                                    >連絡先電話番号</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-tel" class="input__container input__container-tel">
                                                <input
                                                    id="input__tel01"
                                                    class="input__form input__tel01"
                                                    name="input__tel01"
                                                    type="text"
                                                    v-model="tel1"
                                                />
                                                <span>-</span>
                                                <input
                                                    id="input__tel02"
                                                    class="input__form input__tel02"
                                                    name="input__tel02"
                                                    type="text"
                                                    v-model="tel2"
                                                />
                                                <span>-</span>
                                                <input
                                                    id="input__tel03"
                                                    class="input__form input__tel03"
                                                    name="input__tel03"
                                                    type="text"
                                                    v-model="tel3"
                                                />
                                            </dd>
                                            <p class="input__error-text">{{ tel_error }}</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- postnum -->
                                        <div id="input__item-postnum" class="input__item input__item-postnum">
                                            <dt id="input__header-postnum" class="input__header input__header-postnum">
                                                <label id="input__name-postnum" class="input__name input__name-postnum" for="input__postnum"
                                                    >郵便番号</label
                                                >
                                            </dt>
                                            <dd id="input__container-postnum" class="input__container input__container-postnum">
                                                <input
                                                    id="input__postnum"
                                                    class="input__form input__postnum"
                                                    type="text"
                                                    name="input__postnum"
                                                    v-model="post_code"
                                                />
                                                <button id="btn-address" class="btn btn-address" type="button" v-on:click="setAddressFromPostCode">
                                                    郵便番号から住所確認
                                                </button>
                                            </dd>
                                            <p class="input__error-text">{{ post_code_error }}</p>
                                        </div>
                                        <!-- pref -->
                                        <div id="input__item-pref" class="input__item input__item-pref">
                                            <dt id="input__header-pref" class="input__header input__header-pref">
                                                <label id="input__name-pref" class="input__name input__name-pref" for="input__pref">都道府県</label>
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-pref" class="input__container input__container-pref">
                                                <select id="input__pref" class="input__select input__pref" name="input__pref" v-model="prefectures">
                                                    <option :value="null">選択してください</option>
                                                    <template v-for="prefecture in PREFECTURES" :key="prefecture.value">
                                                        <option :value="prefecture.value">
                                                            {{ prefecture.text }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </dd>
                                            <p class="input__error-text">都道府県を選択してください</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- address -->
                                        <div id="input__item-address" class="input__item input__item-address">
                                            <dt id="input__header-address" class="input__header input__header-address">
                                                <label id="input__name-address" class="input__name input__name-address" for="input__address"
                                                    >住所</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-address" class="input__container input__container-address">
                                                <textarea
                                                    id="input__address"
                                                    class="input__form input__address"
                                                    name="input__address"
                                                    v-model="address"
                                                ></textarea>
                                            </dd>
                                            <p class="input__error-text">住所を入力してください</p>
                                            <p class="input__text">マンション等にお住まいの方は、建物名まで記入してください。</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- birth -->
                                        <div id="input__item-birth" class="input__item input__item-birth">
                                            <dt id="input__header-birth" class="input__header input__header-birth">
                                                <label id="input__name-birth" class="input__name input__name-birth" for="input__birth-year"
                                                    >生年月日</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-birth" class="input__container input__container-birth">
                                                <div class="input__container-birth-pack">
                                                    <select
                                                        id="input__birth-year"
                                                        class="input__form input__birth input__birth-year"
                                                        name="input__birth-year"
                                                        type="text"
                                                        v-model="birthday_year"
                                                    >
                                                        <option value="">--</option>
                                                        <option value="1920">1920</option>
                                                        <option value="1921">1921</option>
                                                        <option value="1922">1922</option>
                                                        <option value="1923">1923</option>
                                                        <option value="1924">1924</option>
                                                        <option value="1925">1925</option>
                                                        <option value="1926">1926</option>
                                                        <option value="1927">1927</option>
                                                        <option value="1928">1928</option>
                                                        <option value="1929">1929</option>
                                                        <option value="1930">1930</option>
                                                        <option value="1931">1931</option>
                                                        <option value="1932">1932</option>
                                                        <option value="1933">1933</option>
                                                        <option value="1934">1934</option>
                                                        <option value="1935">1935</option>
                                                        <option value="1936">1936</option>
                                                        <option value="1937">1937</option>
                                                        <option value="1938">1938</option>
                                                        <option value="1939">1939</option>
                                                        <option value="1940">1940</option>
                                                        <option value="1941">1941</option>
                                                        <option value="1942">1942</option>
                                                        <option value="1943">1943</option>
                                                        <option value="1944">1944</option>
                                                        <option value="1945">1945</option>
                                                        <option value="1946">1946</option>
                                                        <option value="1947">1947</option>
                                                        <option value="1948">1948</option>
                                                        <option value="1949">1949</option>
                                                        <option value="1950">1950</option>
                                                        <option value="1951">1951</option>
                                                        <option value="1952">1952</option>
                                                        <option value="1953">1953</option>
                                                        <option value="1954">1954</option>
                                                        <option value="1955">1955</option>
                                                        <option value="1956">1956</option>
                                                        <option value="1957">1957</option>
                                                        <option value="1958">1958</option>
                                                        <option value="1959">1959</option>
                                                        <option value="1960">1960</option>
                                                        <option value="1961">1961</option>
                                                        <option value="1962">1962</option>
                                                        <option value="1963">1963</option>
                                                        <option value="1964">1964</option>
                                                        <option value="1965">1965</option>
                                                        <option value="1966">1966</option>
                                                        <option value="1967">1967</option>
                                                        <option value="1968">1968</option>
                                                        <option value="1969">1969</option>
                                                        <option value="1970">1970</option>
                                                        <option value="1971">1971</option>
                                                        <option value="1972">1972</option>
                                                        <option value="1973">1973</option>
                                                        <option value="1974">1974</option>
                                                        <option value="1975">1975</option>
                                                        <option value="1976">1976</option>
                                                        <option value="1977">1977</option>
                                                        <option value="1978">1978</option>
                                                        <option value="1979">1979</option>
                                                        <option value="1980">1980</option>
                                                        <option value="1981">1981</option>
                                                        <option value="1982">1982</option>
                                                        <option value="1983">1983</option>
                                                        <option value="1984">1984</option>
                                                        <option value="1985">1985</option>
                                                        <option value="1986">1986</option>
                                                        <option value="1987">1987</option>
                                                        <option value="1988">1988</option>
                                                        <option value="1989">1989</option>
                                                        <option value="1990">1990</option>
                                                        <option value="1991">1991</option>
                                                        <option value="1992">1992</option>
                                                        <option value="1993">1993</option>
                                                        <option value="1994">1994</option>
                                                        <option value="1995">1995</option>
                                                        <option value="1996">1996</option>
                                                        <option value="1997">1997</option>
                                                        <option value="1998">1998</option>
                                                        <option value="1999">1999</option>
                                                        <option value="2000">2000</option>
                                                        <option value="2001">2001</option>
                                                        <option value="2002">2002</option>
                                                        <option value="2003">2003</option>
                                                        <option value="2004">2004</option>
                                                        <option value="2005">2005</option>
                                                        <option value="2006">2006</option>
                                                        <option value="2007">2007</option>
                                                        <option value="2008">2008</option>
                                                        <option value="2009">2009</option>
                                                        <option value="2010">2010</option>
                                                        <option value="2011">2011</option>
                                                        <option value="2012">2012</option>
                                                        <option value="2013">2013</option>
                                                        <option value="2014">2014</option>
                                                        <option value="2015">2015</option>
                                                        <option value="2016">2016</option>
                                                        <option value="2017">2017</option>
                                                        <option value="2018">2018</option>
                                                        <option value="2019">2019</option>
                                                        <option value="2020">2020</option>
                                                        <option value="2021">2021</option>
                                                        <option value="2022">2022</option>
                                                        <option value="2023">2023</option>
                                                    </select>
                                                    <span>年</span>
                                                    <select
                                                        id="input__birth-month"
                                                        class="input__form input__birth input__birth-month"
                                                        name="input__birth-month"
                                                        type="text"
                                                        v-model="birthday_month"
                                                    >
                                                        <option value="">--</option>
                                                        <option value="01">1</option>
                                                        <option value="02">2</option>
                                                        <option value="03">3</option>
                                                        <option value="04">4</option>
                                                        <option value="05">5</option>
                                                        <option value="06">6</option>
                                                        <option value="07">7</option>
                                                        <option value="08">8</option>
                                                        <option value="09">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                    <span>月</span>
                                                    <select
                                                        id="input__birth-day"
                                                        class="input__form input__birth input__birth-day"
                                                        name="input__birth-day"
                                                        type="text"
                                                        v-model="birthday_day"
                                                    >
                                                        <option value="">--</option>
                                                        <option value="01">1</option>
                                                        <option value="02">2</option>
                                                        <option value="03">3</option>
                                                        <option value="04">4</option>
                                                        <option value="05">5</option>
                                                        <option value="06">6</option>
                                                        <option value="07">7</option>
                                                        <option value="08">8</option>
                                                        <option value="09">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                        <option value="26">26</option>
                                                        <option value="27">27</option>
                                                        <option value="28">28</option>
                                                        <option value="29">29</option>
                                                        <option value="30">30</option>
                                                        <option value="31">31</option>
                                                    </select>
                                                    <span>日</span>
                                                </div>
                                                <p class="input__error-text">生年月日を選択してください</p>
                                                <p class="input__text">
                                                    <a v-on:click="openContrast">西暦・和暦対照表</a>
                                                </p>
                                            </dd>
                                        </div>
                                        <!-- sex -->
                                        <div id="input__item-sex" class="input__item input__item-sex">
                                            <dt id="input__header-sex" class="input__header input__header-sex">
                                                <label id="input__name-sex" class="input__name input__name-sex" for="">性別</label>
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-sex" class="input__container input__container-sex">
                                                <div id="input__radio-sex-man" class="input__radio input__radio-sex input__radio-sex-man">
                                                    <input
                                                        id="input__man"
                                                        class="input__radio-btn input__sex input__man"
                                                        type="radio"
                                                        name="input__sex"
                                                        v-model="sex"
                                                        :value="GENDER_MALE"
                                                    />
                                                    <label id="input__label-man" class="input__radio-label input__label-man" for="input__man"
                                                        >男</label
                                                    >
                                                </div>
                                                <div id="input__radio-sex-woman" class="input__radio input__radio-sex input__radio-sex-woman">
                                                    <input
                                                        id="input__woman"
                                                        class="input__radio-btn input__sex input__woman"
                                                        type="radio"
                                                        name="input__sex"
                                                        v-model="sex"
                                                        :value="GENDER_FEMALE"
                                                    />
                                                    <label id="input__label-woman" class="input__radio-label input__label-woman" for="input__woman"
                                                        >女</label
                                                    >
                                                </div>
                                            </dd>
                                            <p class="input__error-text">性別を選択してください</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- magazine -->
                                        <div id="input__item-magazine" class="input__item input__item-magazine">
                                            <dt id="input__header-magazine" class="input__header input__header-magazine">
                                                <label id="input__name-magazine" class="input__name input__name-magazine" for=""
                                                    >メールマガジン登録</label
                                                >
                                            </dt>
                                            <dd id="input__container-magazine" class="input__container input__container-magazine">
                                                <input
                                                    id="input__magazine"
                                                    class="input__checkbox-btn input__magazine"
                                                    type="checkbox"
                                                    name="input__magazine"
                                                    v-model="mail_magazine_request"
                                                />
                                                <label
                                                    id="input__label-magazine"
                                                    class="input__checkbox-label input__label-magazine"
                                                    for="input__magazine"
                                                    >メールマガジンの配信を希望します。</label
                                                >
                                            </dd>
                                            <p class="input__error-text">メールマガジン登録を選択してください</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line" v-if="isEditInput">
                                        <!-- terms -->
                                        <div id="input__item-terms" class="input__item input__item-terms">
                                            <dt id="input__header-terms" class="input__header input__header-terms">
                                                <label id="input__name-terms" class="input__name input__name-terms" for="">利用規約</label>
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-terms" class="input__container input__container-terms">
                                                <input
                                                    id="input__terms"
                                                    class="input__checkbox-btn input__terms"
                                                    type="checkbox"
                                                    name="input__terms"
                                                    v-model="terms_agreement"
                                                />
                                                <label id="input__label-terms" class="input__checkbox-label input__label-terms" for="input__terms"
                                                    >MCF会員規約とプライバシーポリシーに同意します。</label
                                                >
                                            </dd>
                                            <p class="input__error-text">利用規約の同意にチェックしてください</p>
                                            <p class="input__text">MCF会員規約とプライバシーポリシーにご同意の上、確認画面へお進みください。</p>
                                            <p class="input__link">
                                                <a href="/member/membership_terms" target="_blank">MCF会員規約</a>
                                            </p>
                                            <p class="input__link">
                                                <a href="/member/privacy_policy" target="_blank">プライバシーポリシー</a>
                                            </p>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container" v-if="isEditInput">
                <button class="btn btn-next" type="button" v-on:click="confirm">会員情報確認へ進む</button>
            </div>
            <div class="btn-container" v-if="isEditUpdate">
                <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
                <button class="btn btn-next" type="button" v-on:click="confirm">会員情報を変更する</button>
            </div>
        </section>
        <!-- /form -->
        <!-- confirm -->
        <button id="btn-confirm-open-modal" data-remodal-target="confirm" v-show="false">○</button>
        <button id="btn-confirm-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="confirm" class="confirm remodal normal" data-remodal-id="confirm">
            <MemberConfirm
                :type="type"
                :email="email"
                :password="password"
                :member_id="member_id"
                :family_name="family_name"
                :given_name="given_name"
                :family_name_katakana="family_name_katakana"
                :given_name_katakana="given_name_katakana"
                :tel="tel1 + '-' + tel2 + '-' + tel3"
                :post_code="post_code"
                :prefectures="prefectures"
                :address="address"
                :birthday="birthday_year + '-' + birthday_month + '-' + birthday_day"
                :sex="sex"
                :mail_magazine_request="mail_magazine_request"
                v-on:memberCreate="memberCreate"
                v-on:memberUpdate="memberUpdate"
            ></MemberConfirm>
        </section>
        <!-- /confirm -->
        <!-- contrast -->
        <button id="btn-contrast-open-modal" data-remodal-target="contrast" v-show="false">○</button>
        <button id="btn-contrast-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="contrast" class="contrast remodal normal" data-remodal-id="contrast">
            <ContrastCalender></ContrastCalender>
        </section>
        <!-- /contrast -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, computed, nextTick } from 'vue'
import $ from 'jquery'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { PREFECTURES, GENDER_MALE, GENDER_FEMALE } from '@/lib/constants'
import { POST_MEMBER_ERROR_UN_MATCH_USER_KEY, RECAPTCHA_AUTH_ERROR } from '@/lib/constants_error'
import { API_USERS, isNull, isLogged, getId, searchAddress } from '@/lib/util'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import MemberConfirm from './MemberConfirm.vue'
import ContrastCalender from './ContrastCalender.vue'
import { isMemberId, isPassword, isPostCode, isZenKatakana, isNumeric, isFullWidth, isHalfWidth } from '@/lib/validation_util'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const recaptchaInstance = useReCaptcha()

        const TYPE_INS = 0
        const TYPE_UPD = 1
        const type = ref(TYPE_INS)

        const user_key = ref('')

        const member_id = ref('')
        const member_id_status = ref<boolean | null>(null)
        const family_name = ref('')
        const given_name = ref('')
        const family_name_katakana = ref('')
        const given_name_katakana = ref('')
        const tel1 = ref('')
        const tel2 = ref('')
        const tel3 = ref('')
        const post_code = ref('')
        const prefectures = ref<string | undefined>(undefined)
        const address = ref('')
        const sex = ref()
        const birthday_year = ref('')
        const birthday_month = ref('')
        const birthday_day = ref('')
        const email = ref('')
        const mail_magazine_request = ref(false)
        const email_etc1 = ref('')
        const mail_magazine_request1 = ref(false)
        const email_etc2 = ref('')
        const mail_magazine_request2 = ref(false)
        const terms_version = ref('')
        const terms_agreement = ref(false)
        const password = ref('')
        const password_confirm = ref('')
        const privacy_policy_confirm_datetime = ref<Date | null>(null)
        const terms_confirm_datetime = ref<Date | null>(null)
        const name_error = ref('')
        const kana_error = ref('')
        const tel_error = ref('')
        const post_code_error = ref('')

        const tel = computed(() => {
            return tel1.value + '-' + tel2.value + '-' + tel3.value
        })
        const birthday = computed(() => {
            return birthday_year.value + '-' + birthday_month.value + '-' + birthday_day.value
        })
        const isEditInput = computed(() => {
            if (type.value == TYPE_INS) {
                return true
            } else {
                return false
            }
        })
        const isEditUpdate = computed(() => {
            if (type.value == TYPE_UPD) {
                return true
            } else {
                return false
            }
        })

        // ログインページへ
        const moveLogin = () => {
            router.push('/member/login')
        }
        // マイページへ
        const moveMyPage = () => {
            router.push('/member/my_page')
            location.href = '/member/my_page'
        }
        // 退会画面へ
        const moveWithdrawal = () => {
            router.push('/member/withdrawal')
        }
        // 入力チェック
        const chkValidation = async (): Promise<boolean> => {
            console.log('↓↓↓MemberRegistration.chkValidation↓↓↓')
            let err_count = 0
            name_error.value = ''
            kana_error.value = ''
            tel_error.value = ''
            post_code_error.value = ''

            // エラー表示初期化
            $('#input__item-pass').removeClass('input__error')
            $('#input__item-pass-confirm').removeClass('input__error')
            $('#input__item-user-id').removeClass('input__error')
            $('#input__item-fullname').removeClass('input__error')
            $('#input__item-fullname-katakana').removeClass('input__error')
            $('#input__item-tel').removeClass('input__error')
            $('#input__item-postnum').removeClass('input__error')
            $('#input__item-pref').removeClass('input__error')
            $('#input__item-address').removeClass('input__error')
            $('#input__item-birth').removeClass('input__error')
            $('#input__item-sex').removeClass('input__error')
            $('#input__item-magazine').removeClass('input__error')
            $('#input__item-terms').removeClass('input__error')
            $('#password-error').text('')
            $('#password-confirm-error').text('')

            // パスワード
            if (isEditInput.value) {
                if (isNull(password.value)) {
                    $('#input__item-pass').addClass('input__error')
                    $('#password-error').text('パスワードを入力してください')
                    err_count++
                } else if (!isHalfWidth(password.value)) {
                    $('#input__item-pass').addClass('input__error')
                    $('#password-error').text('半角英数字で入力してください')
                    err_count++
                } else if (!isPassword(password.value)) {
                    $('#input__item-pass').addClass('input__error')
                    $('#password-error').text('8文字以上20文字以内の英大文字、英小文字、半角数字の組み合わせで入力してください')
                    err_count++
                } else if (!isNull(member_id.value) && password.value.toLowerCase().includes(member_id.value)) {
                    $('#input__item-pass').addClass('input__error')
                    $('#password-error').text('利用者IDを含むパスワードは使用できません')
                    err_count++
                }
            }
            // パスワード（確認用）
            if (isEditInput.value) {
                if (isNull(password_confirm.value)) {
                    $('#input__item-pass-confirm').addClass('input__error')
                    $('#password-confirm-error').text('パスワード（確認用）を入力してください')
                    err_count++
                } else if (password.value != password_confirm.value) {
                    $('#input__item-pass-confirm').addClass('input__error')
                    $('#password-confirm-error').text('確認用パスワードにはパスワードと同じものを入力してください')
                    err_count++
                }
            }
            // 利用者ID
            if (isEditInput.value) {
                if (isNull(member_id.value) || !isMemberId(member_id.value)) {
                    $('#input__item-user-id').addClass('input__error')
                    err_count++
                } else {
                    await chkMemberId(true)
                    if (member_id_status.value != true) {
                        $('#input__item-user-id').addClass('input__error')
                        err_count++
                    }
                }
            }
            // お名前
            if (isNull(family_name.value) || isNull(given_name.value)) {
                $('#input__item-fullname').addClass('input__error')
                name_error.value = 'お名前を入力してください'
                err_count++
            } else if (!isFullWidth(family_name.value) || !isFullWidth(given_name.value)) {
                $('#input__item-fullname').addClass('input__error')
                name_error.value = 'お名前は全角で入力してください'
                err_count++
            }
            // お名前（カナ）
            if (isNull(family_name_katakana.value) || isNull(given_name_katakana.value)) {
                $('#input__item-fullname-katakana').addClass('input__error')
                kana_error.value = 'お名前を入力してください'
                err_count++
            } else if (!isZenKatakana(family_name_katakana.value) || !isZenKatakana(given_name_katakana.value)) {
                $('#input__item-fullname-katakana').addClass('input__error')
                kana_error.value = '全角カタカナで入力してください'
                err_count++
            }
            // 連絡先電話番号
            if (isNull(tel1.value) || isNull(tel2.value) || isNull(tel3.value)) {
                $('#input__item-tel').addClass('input__error')
                tel_error.value = '連絡先電話番号を入力してください'
                err_count++
            } else if (
                !isNumeric(tel1.value) ||
                !isNumeric(tel2.value) ||
                !isNumeric(tel3.value) ||
                !isHalfWidth(tel1.value) ||
                !isHalfWidth(tel2.value) ||
                !isHalfWidth(tel3.value)
            ) {
                $('#input__item-tel').addClass('input__error')
                tel_error.value = '連絡先電話番号は半角数字で入力してください'
                err_count++
            }
            // 郵便番号
            if (!isNull(post_code.value)) {
                if (!isHalfWidth(post_code.value)) {
                    $('#input__item-postnum').addClass('input__error')
                    post_code_error.value = '郵便番号は半角で入力してください'
                    err_count++
                } else if (!isPostCode(post_code.value)) {
                    $('#input__item-postnum').addClass('input__error')
                    post_code_error.value = '郵便番号は000-0000形式で入力してください'
                    err_count++
                }
            }
            // 都道府県
            if (isNull(prefectures.value)) {
                $('#input__item-pref').addClass('input__error')
                err_count++
            }
            // 住所
            if (isNull(address.value)) {
                $('#input__item-address').addClass('input__error')
                err_count++
            }
            // 生年月日
            if (isNull(birthday_year.value) || isNull(birthday_month.value) || isNull(birthday_day.value)) {
                $('#input__item-birth').addClass('input__error')
                err_count++
            }
            // 性別
            if (isNull(sex.value)) {
                $('#input__item-sex').addClass('input__error')
                err_count++
            }
            // // メールマガジン登録
            // if (isNull(mail_magazine_request.value)) {
            //   $('#input__item-magazine').addClass('input__error');
            //   err_count++;
            // }
            // 利用規約
            if (isEditInput.value) {
                if (!terms_agreement.value) {
                    $('#input__item-terms').addClass('input__error')
                    err_count++
                }
            }

            console.log('↑↑↑MemberRegistration.chkValidation↑↑↑')
            return err_count == 0
        }
        // 会員情報確認へ進む
        const confirm = async (): Promise<void> => {
            let valid = await chkValidation()
            if (!valid) {
                // Scroll
                var landing = $('#form__list').offset()!.top
                $('html,body').animate({ scrollTop: landing })
                return
            }
            // location.href = '#confirm';
            $('#btn-confirm-open-modal').click()
        }
        // 会員情報新規登録を行う
        const memberCreate = async (): Promise<void> => {
            try {
                store.dispatch('setIsProcessing', true)
                await recaptchaInstance?.recaptchaLoaded()
                const token = await recaptchaInstance?.executeRecaptcha('login')

                const postData = {
                    user_key: user_key.value,
                    member_id: member_id.value,
                    family_name: family_name.value,
                    given_name: given_name.value,
                    family_name_katakana: family_name_katakana.value,
                    given_name_katakana: given_name_katakana.value,
                    tel: tel.value,
                    post_code: post_code.value,
                    prefectures: prefectures.value,
                    address: address.value,
                    sex: sex.value,
                    birthday: birthday.value,
                    password: password.value,
                    mail_magazine_request: mail_magazine_request.value,
                    token: token,
                }
                await apiClient
                    .post(`${API_USERS}/member`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        store.dispatch('setIsProcessing', false)
                        router.push('/member/member_registration_complete')
                    })
                    .catch((e: AxiosError<any>) => {
                        // エラー処理
                        store.dispatch('setIsProcessing', false)
                        console.log(e.message)
                        if (!isNull(e.response)) {
                            if (e.response?.data == POST_MEMBER_ERROR_UN_MATCH_USER_KEY) {
                                // エラー：ユーザー識別キー
                                context.emit('displayMessage', {
                                    msg_title: '会員情報の登録',
                                    msg: ['ご登録URLの有効期間が過ぎました。新規利用登録からやり直してください。'],
                                    close_btn: true,
                                })
                                return
                            }
                            if (!isNull(e.response?.data?.message) && e.response?.data.message == RECAPTCHA_AUTH_ERROR) {
                                // エラー：reCAPTCHA認証エラー
                                context.emit('displayMessage', {
                                    msg_title: '登録に失敗しました',
                                    msg: [
                                        'セキュリティ対策により、認証が正常に行えませんでした（reCAPTCHAによるBOT判定）',
                                        '　',
                                        'お客様のアクセス環境により、セキュリティ対策が反応して認証が正常に行えないことがあります。',
                                        '以下の方法をお試しいただくことで、正常にご利用いただける場合があります。',
                                        '・入力をゆっくりおこない、予測変換や短縮入力を避ける',
                                        '・現在ご利用のブラウザを閉じ、別のブラウザで操作をおこなう',
                                        '・他の端末（PC⇔スマートフォンなど）で操作をおこなう',
                                        '・しばらく時間をおいてから、再度操作をおこなう',
                                    ],
                                    close_btn: true,
                                })
                                return
                            }
                        }
                        context.emit('displayMessage', {
                            msg_title: '登録に失敗しました',
                            msg: ['登録内容をご確認いただき、登録をやり直してください'],
                            close_btn: true,
                        })
                        return
                    })
            } catch (e) {
                console.log(e)
                store.dispatch('setIsProcessing', false)
            }
        }
        // 郵便番号から住所確認
        const setAddressFromPostCode = async (): Promise<void> => {
            console.log('↓↓↓RoomList.setAddressFromPostCode↓↓↓')
            // 郵便番号から住所確認
            let isChk = false
            if (isNull(post_code.value)) {
                return
            }
            let post_codes = post_code.value.split('-')
            let post_code_1 = ''
            let post_code_2 = ''
            if (post_codes.length == 1 && post_codes[0].length == 7) {
                post_code_1 = post_codes[0].substring(0, 3)
                post_code_2 = post_codes[0].substring(3, 7)
            } else if (post_codes.length == 2) {
                post_code_1 = post_codes[0]
                post_code_2 = post_codes[1]
            }
            if (post_code_1.length == 3 && post_code_2.length == 4) {
                post_code.value = post_code_1 + '-' + post_code_2
                isChk = true
            }
            if (isChk) {
                const post_code_address = await searchAddress(post_code.value)
                console.log(post_code_address)
                prefectures.value = post_code_address.region_id
                address.value = post_code_address.locality
                address.value += post_code_address.street
            }
            console.log('↑↑↑RoomList.setAddressFromPostCode↑↑↑')
        }
        // 利用者ID使用チェック
        const chkMemberId = async (isSilent: boolean): Promise<void> => {
            member_id_status.value = null
            // 入力チェック
            if (isEditInput.value) {
                $('#input__item-user-id').removeClass('input__error')
                if (isNull(member_id.value) || !isMemberId(member_id.value)) {
                    $('#input__item-user-id').addClass('input__error')
                    return
                }
            }
            const postData = {
                member_id: member_id.value,
            }
            try {
                if (!isSilent) store.dispatch('setIsProcessing', true)
                await apiClient
                    .post(`${API_USERS}/member/id_check`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        if (data.use_count == 0) {
                            member_id_status.value = true
                        } else {
                            member_id_status.value = false
                        }
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                    })
            } catch (e) {
                console.log(e)
            } finally {
                if (!isSilent) store.dispatch('setIsProcessing', false)
            }
        }
        // 会員情報取得
        const getMemberData = (): void => {
            console.log('getMemberData')
            let id = getId()
            const postData = {
                id: id,
            }
            apiClient
                .get(`${API_USERS}/member`, { params: postData })
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    member_id.value = data.member_id
                    family_name.value = data.family_name
                    given_name.value = data.given_name
                    family_name_katakana.value = data.family_name_katakana
                    given_name_katakana.value = data.given_name_katakana
                    tel1.value = data.tel1
                    tel2.value = data.tel2
                    tel3.value = data.tel3
                    post_code.value = data.post_code
                    prefectures.value = data.prefectures
                    address.value = data.address
                    sex.value = data.sex
                    birthday_year.value = data.birthday_year
                    birthday_month.value = data.birthday_month
                    birthday_day.value = data.birthday_day
                    email.value = data.email
                    mail_magazine_request.value = Boolean(data.mail_magazine_request)
                    privacy_policy_confirm_datetime.value = data.privacy_policy_confirm_datetime
                    terms_confirm_datetime.value = data.terms_confirm_datetime
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
        }
        // 会員情報の更新
        const memberUpdate = async (): Promise<void> => {
            try {
                store.dispatch('setIsProcessing', true)
                await recaptchaInstance?.recaptchaLoaded()
                const token = await recaptchaInstance?.executeRecaptcha('login')

                const postData = {
                    family_name: family_name.value,
                    given_name: given_name.value,
                    family_name_katakana: family_name_katakana.value,
                    given_name_katakana: given_name_katakana.value,
                    tel: tel.value,
                    post_code: post_code.value,
                    prefectures: prefectures.value,
                    address: address.value,
                    sex: sex.value,
                    birthday: birthday.value,
                    mail_magazine_request: mail_magazine_request.value,
                    token: token,
                }
                await apiClient
                    .patch(`${API_USERS}/member`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        store.dispatch('setIsProcessing', false)
                        // マイページへ遷移
                        moveMyPage()
                    })
                    .catch((e: AxiosError<any>) => {
                        store.dispatch('setIsProcessing', false)
                        // エラー処理
                        console.log(e.message)
                        let message: string[] = ['変更内容をご確認いただき、変更をやり直してください']
                        if (!isNull(e.response)) {
                            if (!isNull(e.response?.data?.message) && e.response?.data.message == RECAPTCHA_AUTH_ERROR) {
                                // エラー：reCAPTCHA認証エラー
                                message = [
                                    'セキュリティ対策により、認証が正常に行えませんでした（reCAPTCHAによるBOT判定）',
                                    '　',
                                    'お客様のアクセス環境により、セキュリティ対策が反応して認証が正常に行えないことがあります。',
                                    '以下の方法をお試しいただくことで、正常にご利用いただける場合があります。',
                                    '・入力をゆっくりおこない、予測変換や短縮入力を避ける',
                                    '・現在ご利用のブラウザを閉じ、別のブラウザで操作をおこなう',
                                    '・他の端末（PC⇔スマートフォンなど）で操作をおこなう',
                                    '・しばらく時間をおいてから、再度操作をおこなう',
                                ]
                                return
                            }
                        }
                        context.emit('displayMessage', {
                            msg_title: '変更に失敗しました',
                            msg: message,
                            close_btn: true,
                        })
                    })
            } catch (e) {
                console.log(e)
                store.dispatch('setIsProcessing', false)
            }
        }

        // 会員情報新規登録URL有効期限チェック
        const chkMemberCreate = () => {
            const postData = {
                user_key: user_key.value,
            }
            apiClient.post(`${API_USERS}/member/create/check`, postData).catch((e: AxiosError) => {
                // エラー処理
                console.log(e.message)
                context.emit('displayMessage', {
                    msg_title: '会員情報の登録',
                    msg: ['ご登録URLの有効期間が過ぎました。新規利用登録からやり直してください。'],
                    close_btn: true,
                })
                moveLogin()
            })
        }

        // 西暦・和暦対照表
        const openContrast = () => {
            $('#btn-contrast-open-modal').click()
        }

        onMounted(() => {
            console.log('↓↓↓MemberRegistration.onMounted↓↓↓')
            const route = useRoute()
            user_key.value = route.params.user_key as string
            if (isNull(user_key.value)) {
                // 登録情報更新
                if (!isLogged()) {
                    // ログイン時のみ使用可能
                    router.push('/member/login')
                } else {
                    type.value = TYPE_UPD
                    // 会員情報取得
                    getMemberData()
                }
            } else {
                // 新規登録（仮登録メールから）
                type.value = TYPE_INS
                chkMemberCreate()
            }
            nextTick(() => {
                try {
                    // モーダル再読み込み
                    const remodalInst = $('.remodal').remodal()
                    remodalInst.reload()
                } catch (e) {
                    console.log(e)
                }
            })

            console.log('↑↑↑MemberRegistration.onMounted↑↑↑')
        })

        return {
            user_key,
            type,

            member_id,
            member_id_status,
            family_name,
            given_name,
            family_name_katakana,
            given_name_katakana,
            tel1,
            tel2,
            tel3,
            post_code,
            prefectures,
            address,
            sex,
            birthday_year,
            birthday_month,
            birthday_day,
            email,
            mail_magazine_request,
            email_etc1,
            mail_magazine_request1,
            email_etc2,
            mail_magazine_request2,
            terms_version,
            terms_agreement,
            password,
            password_confirm,

            name_error,
            kana_error,
            tel_error,
            post_code_error,

            isEditInput,
            isEditUpdate,

            setAddressFromPostCode,
            chkMemberId,
            confirm,
            memberCreate,
            memberUpdate,

            moveLogin,
            moveMyPage,
            moveWithdrawal,

            openContrast,

            isNull,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'MemberRegistration',
    data() {
        return {
            GENDER_MALE: GENDER_MALE as number,
            GENDER_FEMALE: GENDER_FEMALE as number,
            PREFECTURES: PREFECTURES,
        }
    },
    components: {
        ShipAnimationEnd,
        MemberConfirm,
        ContrastCalender,
    },
    methods: {},
    mounted: function () {
        //password disp
        $(function () {
            $('.password button').click(function () {
                if ($(this).prev('input').is('[type=text]')) {
                    $(this).prev('input').attr('type', 'password')
                    $(this).removeClass('disppass')
                } else if ($(this).prev('input').is('[type=password]')) {
                    $(this).prev('input').attr('type', 'text')
                    $(this).addClass('disppass')
                }
            })
        })
    },
})
</script>

<style></style>
